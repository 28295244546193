
.color-yellow {
  color: #ffc800 !important;
}

.pagination ul {
  list-style-type: none;
  display: flex;
  gap: .5rem;
}

.pagination ul li {
  border: 1px solid lightgray;
  /* padding: 0 .5rem; */
  font-size: 1rem;
  align-items: center;
  display: flex;
  padding: 0.2rem 0.8rem;
  border-radius: 0.375rem;
}

.pagination ul li.selected {
  /* background-color: #215fb6;
  border-color: #215fb6; */
  background-color: #1f51fe;
  border-color: #1f51fe;
  color: white;
}

.pagination ul li.next, .pagination ul li.previous {
  padding: 0.2rem 0.8rem;
  border-radius: 0.375rem;
  color: #1f51fe;
}

.pagination ul li a {
  text-decoration: none;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 0.1em 0.6em;
  margin: -0.1em -0.6em;
}

.box-shadow-container {
  box-shadow: 0 .1875rem .75rem 0 rgba(47,43,61,.14);
  border-radius: 0.375rem;
  background: #fff;
  padding: 1.5rem;
}

.loading-row-table {
  color: #fad85a !important;
  border: 2px solid #1F51FE !important;
  animation-duration: 2s !important;
}

.loading-inprogess-statement {
  width: 200px;
}