@media only screen and (max-width: 600px) {
    .guideline-qrcode {
        width: 100%;
    }
}

@media only screen and (min-width: 600px) {
    .guideline-qrcode {
        width: 400px;
    }
}

