.custom-alert {
    border-radius: 50px !important;
    font-weight: 600;
    padding: 6px 12px !important;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    float: right;
}

.custom-alert-verified {
    background-color: #EFFFF3 !important;
    color: #60c275 !important;
    border: 1px solid #dcf1e5 !important;
}

.custom-alert-not_verified {
    background-color: #ffdcde !important;
    color: #B34D58 !important;
    border: 1px solid #da8b92 !important;
}

.custom-alert-pending {
    background-color: #fdeff1 !important;
    color: #b28f3a !important;
    border: 1px solid #F2DFE2 !important;
}